.games-container {
  /* As flex container */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  width: 80%;

  /* As flex item */
  flex: 1 1 auto;
}
