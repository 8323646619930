.nav-container {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 1em;
  justify-content: center;
  transition: 0.5s;
  white-space: nowrap;
}

h2 {
  text-align: center;
  color: white;
  transition: 0.5s;
}
