.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
}

.modal.open {
  z-index: 9999;
  opacity: 1;
  visibility: visible;
}

.modal-loading {
  text-align: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.modal-content {
  position: relative;
  z-index: 9999;
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
}

.modal-header {
  margin-bottom: 20px;
  text-align: center;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
}

.form-field label {
  margin-bottom: 5px;
  font-weight: bold;
}

.form-field input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
}

.form-field.form-actions {
  display: flex;
  justify-content: center;
}

.form-field.form-actions button {
  transition: 0s;
}

.form-field.form-actions button:hover {
  background-color: #0062cc;
}
