/* Colors */
:root {
  --color-gray-light: #f5f5f5;
  --color-gray-medium: #e0e0e0;
  --color-gray-dark: #333333;
  --color-blue: #1a75ff;
}

* {
  margin: 0;
  line-height: calc(1em + 0.5rem);
}

html {
  scroll-behavior: smooth;
}

/* Typography */
body {
  font-family: Arial, sans-serif;
  font-size: 16px;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
  user-select: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  margin-bottom: 1rem;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

p {
  margin-bottom: 1rem;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  padding: 0;
}

button:hover {
  background-color: #0062cc;
}
