.container {
  display: flex;
  justify-content: center;
}

.sidebar {
  max-width: 20%;
  width: 20%;
  position: fixed;
  background-color: maroon;
  height: 100vh;
  left: 0px;
  overflow-y: auto;
  transition: width 0.4s;
}

.sidebar.sidebar-hidden {
  width: 0px;
}

.sidebar-controller {
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  width: 15px;
  height: 100vh;
  background-color: silver;
  cursor: pointer;
  left: 20%;
  transition: left 0.4s;
}

.sidebar-controller.sidebar-hidden {
  left: 0px;
}

.content {
  display: flex;
  justify-content: center;
  background-color: white;
  margin-left: calc(15px + 20%);
  transition: margin-left 0.4s;
  padding: 30px;
}

.content.sidebar-hidden {
  margin-left: 15px;
}
