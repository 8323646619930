table {
  flex: 1 1 auto;
  margin: auto;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

th, td {
  padding: 4px 8px;
  border: 1px solid black;
  background-color: white;
}

th[scope="row"] {
  text-align: left;
}

td {
  text-align: right;
}

caption {
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 8px;
}

.games-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.game {
  display: flex;
  border: 2px solid maroon;
  border-radius: 5px;
  padding: 3px 5px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.game p {
  margin-bottom: 0;
}

.modify-icon {
  margin-left: 5px;
  cursor: pointer;
}
